import React, { useEffect } from "react";
import { useState } from "react";
import { motion, useAnimate, useInView } from "framer-motion";

import { IoMenuOutline, IoClose } from "react-icons/io5";
import { Modal } from ".";
import { links } from "../utils/constants";

export const NavTop = () => {
  const [isVissible, setIsVissible] = useState(false);
  const [scope, animate] = useAnimate(null);
  const isInView = useInView(scope);

  const handleCloseClick = (data) => {
    setIsVissible(false);
  };

  const scrollToSection = (url) => {
    const id = url.split("#")[1];
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (isInView) {
      enterHeroAnimation();
    } else {
      exitHeroAnimation();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const enterHeroAnimation = async () => {
    await animate(
      "#nav-logo",
      { opacity: 1, y: 0 },
      { duration: 1, delay: 1.5 }
    );
  };

  const exitHeroAnimation = async () => {
    await animate("#nav-logo", { opacity: 0, y: -100 });
  };

  return (
    <div
      ref={scope}
      className='flex flex-row w-full h-[70px] fixed top-0 right-0 z-50 px-2.5 md:px-5 bg-gradient-to-b from-gray-800 items-center justify-between'
    >
      <div className='flex h-[40px] min-w-[100px]'>
        <a
          href='/'
          id='nav-logo'
          className='flex flex-row w-full h-[40px] items-center outline-none cursor-pointer'
        >
          <img
            src='/logo.jpg'
            className='h-[40px] w-[40px] rounded-full'
            alt='Manstoper Kennels'
          />
          <p className='text-md text-white font-semibold'>
            &nbsp; Manstoper Kennels
          </p>
        </a>
      </div>

      <div className='flex flex-row items-center justify-end gap-2.5 h-[40px] min-w-[100px]'>
        <div
          id='stagger-links'
          className='hidden md:flex flex-row gap-5 items-center justify-end'
        >
          {links.length &&
            links.map((link, ind) => (
              <motion.a
                key={link.uri}
                initial={{
                  opacity: 0,
                  y: -100,
                  scale: 0.5,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  transition: {
                    type: "spring",
                    damping: 20,
                    delay: ind * 0.5,
                    stiffness: 100,
                  },
                }}
                href={link.uri}
                className='outline-none cursor-pointer text-slate-100 hover:text-blue-400'
              >
                {link.title}
              </motion.a>
            ))}
        </div>

        <IoMenuOutline
          onClick={() => setIsVissible(true)}
          className='flex md:hidden text-3xl text-slate-500 hover:text-blue-400 cursor-pointer'
        />

        <Modal
          isVissible={isVissible}
          title={null}
          subTitle={``}
          handleCloseClick={(data) => handleCloseClick(data)}
        >
          <div className='flex flex-col w-full justify-items-start gap-5 min-h-screen bg-white overflow-hidden'>
            <div className='flex flex-row w-full justify-between items-center'>
              <div className='flex flex-1 h-[40px] min-w-[100px]'>
                <a
                  href='/'
                  className='flex flex-row md:hidden w-full h-[40px] items-center outline-none cursor-pointer'
                >
                  <img
                    width={100}
                    height={30}
                    src='/logo.jpg'
                    className='w-[50px] h-[50px] rounded-full'
                    alt='Manstopper Kennels'
                  />
                  <p className='text-md text-slate-600 font-semibold'>
                    &nbsp; Manstoper Kennels
                  </p>
                </a>
              </div>

              <div className='flex w-[30px]'>
                <IoClose
                  onClick={() => setIsVissible(false)}
                  className='flex md:hidden text-3xl text-slate-500 hover:text-blue-400 cursor-pointer'
                />
              </div>
            </div>

            {links.length ? (
              links.map((link, ind) => (
                <div
                  key={ind}
                  className='flex flex-col w-full gap-2.5 items-center'
                >
                  <button
                    key={ind}
                    className='flex flex-row w-full h-[40px] gap-5 items-center outline-none  rounded-md text-sm text-slate-500 cursor-pointer'
                    onClick={() => {
                      scrollToSection(link.uri);
                      setIsVissible(false);
                    }}
                  >
                    {link.icon}
                    {link.title}
                  </button>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};
