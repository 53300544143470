import React, { useEffect, useState, useRef } from "react";
import { useAnimate, useInView } from "framer-motion";
import { slideAnimation } from "../lib/motion";

import Autoplay from "embla-carousel-autoplay";
import { Card, CardContent } from "../components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel";

export default function About() {
  const [scope, animate] = useAnimate(null);
  const isInView = useInView(scope, { amount: 0.5 });

  const [api, setApi] = useState();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  const plugin = useRef(Autoplay({ delay: 3500, stopOnInteraction: true }));

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  const items = [
    { type: "image", src: "about_1.jpg" },
    { type: "video", src: "agility_puppies.mp4" },
    { type: "video", src: "about_2.mp4" },
    { type: "video", src: "dash.mp4" },
    { type: "video", src: "socializing.mp4" },

    { type: "image", src: "about_2.jpg" },
    { type: "video", src: "about_1.mp4" },
    { type: "video", src: "search.mp4" },
    { type: "image", src: "about_3.jpeg" },
    { type: "video", src: "protection.mp4" },
    { type: "video", src: "training.mp4" },
    { type: "video", src: "puppies.mp4" },
    { type: "video", src: "puppies_eating.mp4" },
    { type: "image", src: "about_4.jpeg" },
  ];

  useEffect(() => {
    if (isInView) {
      enterAnimation();
    } else {
      exitAnimation();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const enterAnimation = async () => {
    animate(
      "#about-carousel",
      { opacity: 1, x: 0, scale: 1 },
      { duration: 1, delay: 0.2 }
    );
    await animate("h1", { opacity: 1, y: 0 }, { duration: 0.5, delay: 0.1 });
    await animate("p", { opacity: 1, y: 0 }, { duration: 0.3, delay: 0.2 });
  };

  const exitAnimation = async () => {
    await animate("h1", { opacity: 0, y: 20 });
    await animate("p", { opacity: 0, y: 20 });
    await animate("#about-carousel", { opacity: 0, x: -100, scale: 0.9 });
  };

  return (
    <div
      ref={scope}
      className='flex min-h-screen w-full m-0 p-0 bg-zinc-900'
      id='about-page'
    >
      <div className='flex flex-col md:flex-row w-full py-48 md:py-80 items-center justify-center text-left px-[5%] md:px-[10%] space-y-10 md:space-y-0 md:space-x-48'>
        <div className='flex flex-1 order-2 md:order-1  flex-col box-border w-full md:w-1/2 max-w-screen md:max-w-3xl'>
          <Carousel
            plugins={[plugin.current]}
            setApi={setApi}
            onMouseEnter={plugin.current.stop}
            onMouseLeave={plugin.current.reset}
            className='w-full'
            id='about-carousel'
          >
            <CarouselContent className='w-full '>
              {items.map((item, index) => (
                <CarouselItem key={index} className='w-full'>
                  <Card className='border-none bg-transparent'>
                    <CardContent className='flex aspect-square items-center justify-center p-0 h-auto bg-none'>
                      {item.type === "image" ? (
                        <img
                          src={`/img/${item.src}`}
                          alt={`Carousel item ${index + 1}`}
                          className='rounded-lg shadow-lg object-cover w-full h-full'
                          {...slideAnimation("up")}
                        />
                      ) : (
                        <video
                          controls
                          className='rounded-lg shadow-lg w-full h-full'
                          {...slideAnimation("up")}
                        >
                          <source
                            src={`/videos/${item.src}`}
                            type='video/mp4'
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </CardContent>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className='hidden md:flex items-center' />
            <CarouselNext className='hidden md:flex items-center' />
          </Carousel>
          <div className='py-10 text-center text-sm text-slate-300'>
            Slide {current} of {count}
          </div>
        </div>
        <div className='flex flex-1 order-1 md:order-2 flex-col w-full md:w-1/2 max-w-full md:max-w-[600px] text-white'>
          <h1 className='text-4xl md:text-6xl mb-5 font-bold text-blue-500'>
            About Us
          </h1>
          <p className='text-lg mb-5 text-slate-100 pr-2.5'>
            At AB Manstoper Kennels Breeders, we take pride in our commitment to
            breeding and training the finest Belgian Malinois dogs for security
            purposes. With a focus on excellence and reliability, we offer a
            range of exceptional services to cater to the needs of both dogs and
            their owners.
          </p>
        </div>
      </div>
    </div>
  );
}
