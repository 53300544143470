import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";

import {
  CheckBadgeIcon,
  HeartIcon,
  FingerPrintIcon,
} from "@heroicons/react/24/solid";

export default function FindCompanion() {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, {
    once: true,
    amount: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: .5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const [availableBreeds] = useState([
    {
      id: 1,
      name: "Max",
      breed: "Belgian Malinois",
      age: "2 years",
      gender: "Male",
      color: "Fawn with black mask",
      price: "$3,500 - $2,000",
      temperament: "Energetic and Intelligent",
      trainingLevel: "Advanced obedience and protection training",
      healthStatus: "Vaccinated and health checked",
      image: "/img/sample_dogs/max.webp",
    },
    {
      id: 2,
      name: "Bella",
      breed: "Belgian Malinois",
      age: "1.5 years",
      gender: "Female",
      color: "Mahogany with black mask",
      price: "$2,800 - $2,000",
      temperament: "Loyal and Protective",
      trainingLevel: "Basic obedience training",
      healthStatus: "Vaccinated and health checked",
      image: "/img/sample_dogs/bella.jpeg",
    },
    {
      id: 3,
      name: "Rocky",
      breed: "Belgian Malinois",
      age: "3 years",
      gender: "Male",
      color: "Fawn with black markings",
      price: "$2,500 - $1,700",
      temperament: "Confident and Alert",
      trainingLevel: "Advanced obedience and agility training",
      healthStatus: "Vaccinated and health checked",
      image: "/img/sample_dogs/rocky.webp",
    },
    {
      id: 4,
      name: "Luna",
      breed: "Belgian Malinois",
      age: "2.5 years",
      gender: "Female",
      color: "Red with black mask",
      price: "$3,500 - $2,300",
      temperament: "Friendly and Playful",
      trainingLevel: "Intermediate obedience training",
      healthStatus: "Vaccinated and health checked",
      image: "/img/sample_dogs/luna.jpg",
    },
    {
      id: 5,
      name: "Zeus",
      breed: "Belgian Malinois",
      age: "1 year",
      gender: "Male",
      color: "Fawn with black mask",
      price: "$3,500 - $2,500",
      temperament: "Energetic and Protective",
      trainingLevel: "Basic obedience training",
      healthStatus: "Vaccinated and health checked",
      image: "/img/sample_dogs/zeus.webp",
    },
    {
      id: 6,
      name: "Mia",
      breed: "Belgian Malinois",
      age: "3.5 years",
      gender: "Female",
      color: "Mahogany with black mask",
      price: "$3,500 - $3,000",
      temperament: "Loyal and Intelligent",
      trainingLevel: "Advanced obedience and protection training",
      healthStatus: "Vaccinated and health checked",
      image: "/img/sample_dogs/mia.jpg",
    },
  ]);

  return (
    <div className='flex flex-col pt-80 w-full bg-zinc-900 items-center'>
      <motion.div
        ref={ref}
        initial='hidden'
        animate={controls}
        variants={containerVariants}
        className='flex flex-col gap-2.5 text-center'
      >
        <motion.h2
          className='text-4xl p-5 md:text-6xl font-bold text-white text-center'
          variants={itemVariants}
        >
          Find Your Perfect Companion
        </motion.h2>
        <motion.p
          className='text-lg p-5 mb-10 text-slate-300 text-center max-w-3xl'
          variants={itemVariants}
        >
          At AB Manstoper Kennels Breeders, we specialize in raising and
          training exceptional Belgian Malinois puppies. Each dog is carefully
          bred, nurtured, and prepared to become a loyal and loving addition to
          your family. Discover our selection of intelligent, active, and
          well-socialized puppies ready to find their forever homes.
        </motion.p>
      </motion.div>

      <ScrollArea className='w-full whitespace-nowrap mt-40 ' id='scroll-area'>
        <motion.div
          ref={ref}
          className='flex flex-row gap-10 pb-20'
          initial='hidden'
          animate={controls}
          variants={containerVariants}
        >
          {availableBreeds?.length &&
            availableBreeds.map((dog, ind) => (
              <>
                <motion.a
                  variants={itemVariants}
                  href='/#contact-page'
                  key={ind}
                  className='block rounded-lg p-4 shadow-sm shadow-indigo-100  bg-white'
                >
                  <img
                    alt={dog.name}
                    src={dog.image}
                    className='h-56 w-full rounded-md object-cover'
                  />

                  <div className='mt-2'>
                    <dl>
                      <div>
                        <dd className='font-medium'>
                          {dog.name},{" "}
                          <small className='text-grey-500'>{dog.age}</small>
                        </dd>
                      </div>
                      <div>
                        <dd className='text-sm text-gray-500'>{dog.color}</dd>
                      </div>
                      <div>
                        <dd className='font-medium text-sm text-gray-500'>
                          {dog.price}
                        </dd>
                      </div>
                    </dl>

                    <div className='mt-6 flex items-center gap-8 text-xs'>
                      <div className='sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2'>
                        <FingerPrintIcon className='h-7 text-indigo-700' />
                        <div className='mt-1.5 sm:mt-0'>
                          <p className='text-gray-500'>Gender</p>
                          <p className='font-medium'>{dog.gender}</p>
                        </div>
                      </div>

                      <div className='sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2'>
                        <HeartIcon className='h-7 text-indigo-700' />
                        <div className='mt-1.5 sm:mt-0'>
                          <p className='text-gray-500'>Vacinated</p>
                          <p className='font-medium'>Rabis,DHPP</p>
                        </div>
                      </div>
                      <div className='sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2'>
                        <CheckBadgeIcon className='h-7 text-indigo-700' />
                        <div className='mt-1.5 sm:mt-0'>
                          <p className='text-gray-500'>Trained</p>
                          <p className='font-medium'>Loyalty,Obedience</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.a>
              </>
            ))}
        </motion.div>

        <ScrollBar orientation='horizontal' />
      </ScrollArea>
    </div>
  );
}
