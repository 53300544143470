import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Blog from "../pages/Blog";
import { ErrorPage } from "../pages/ErrorPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/blog",
    element: <Blog />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <Navigate to='/' replace />,
  },
]);
