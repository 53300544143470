import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { NavTop, Footer } from "../components";
import {
  slideAnimation,
  headerAnimation,
  staggerListAnimation,
} from "../lib/motion";

const vaccinations = [
  {
    name: "Rabies Vaccine",
    purpose:
      "Protects against rabies, a viral disease that affects the central nervous system and is fatal.",
    frequency:
      "Given at 12 to 16 weeks of age with booster shots every 1 to 3 years, depending on local regulations.",
  },
  {
    name: "DHPP Vaccine (DA2PP)",
    purpose:
      "A combination vaccine that protects against Distemper, Adenovirus (Hepatitis), Parainfluenza, and Parvovirus.",
    frequency:
      "Initial series at 6 to 8 weeks, with boosters every 3 to 4 weeks until 16 weeks, then a booster at one year, and every 1 to 3 years thereafter.",
  },
  {
    name: "Leptospirosis Vaccine",
    purpose:
      "Protects against Leptospirosis, a bacterial infection affecting kidneys and liver, and can be transmitted to humans.",
    frequency:
      "Given in combination with the DHPP vaccine, with initial and booster shots followed by annual vaccinations.",
  },
  {
    name: "Bordetella Vaccine",
    purpose:
      "Protects against Bordetella bronchiseptica, causing kennel cough, a highly contagious respiratory disease.",
    frequency:
      "Given as an intranasal, oral, or injectable vaccine every 6 to 12 months.",
  },
  {
    name: "Canine Influenza Vaccine",
    purpose:
      "Protects against canine influenza virus (CIV), causing respiratory illness.",
    frequency:
      "Initial vaccination followed by a booster after 2 to 4 weeks, then annually.",
  },
  {
    name: "Lyme Disease Vaccine",
    purpose:
      "Protects against Lyme disease, transmitted by ticks, causing joint inflammation and other health issues.",
    frequency:
      "Initial series of two shots given 2 to 4 weeks apart, then annually.",
  },
  {
    name: "Canine Coronavirus Vaccine",
    purpose:
      "Protects against canine coronavirus, affecting the intestinal tract.",
    frequency:
      "Less commonly given and not always recommended by all veterinarians.",
  },
];

export default function Blog() {
  return (
    <AnimatePresence>
      <div className='flex flex-col min-h-screen w-full bg-zinc-900'>
        <NavTop />
        <motion.div
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={slideAnimation("up")}
          className='mt-20 mb-48 max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden'
        >
          <motion.div
            className='bg-blue-500 text-white py-6 px-8 text-center'
            variants={headerAnimation}
          >
            <h1 className='text-3xl font-bold'>
              Common Vaccination Shots for Dogs
            </h1>
          </motion.div>
          <div className='p-8'>
            <div className='space-y-6'>
              {vaccinations.map((vaccine, index) => (
                <motion.div
                  key={index}
                  className='flex items-start space-x-4'
                  variants={staggerListAnimation(index * 0.2)}
                >
                  <ShieldCheckIcon className='h-6 w-6 text-blue-500 mt-1' />
                  <div>
                    <h2 className='text-xl font-bold text-gray-800'>
                      {vaccine.name}
                    </h2>
                    <p className='text-gray-600 mt-1'>
                      <span className='font-semibold'>Purpose:</span>{" "}
                      {vaccine.purpose}
                    </p>
                    <p className='text-gray-600 mt-1'>
                      <span className='font-semibold'>Frequency:</span>{" "}
                      {vaccine.frequency}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
            <motion.div className='mt-10' variants={staggerListAnimation(0.2)}>
              <h2 className='text-2xl font-bold text-gray-800'>
                Sample Vaccination Schedule for Puppies
              </h2>
              <ul className='mt-4 space-y-4'>
                {[
                  {
                    schedule:
                      "6-8 weeks: DHPP (Distemper, Hepatitis, Parainfluenza, Parvovirus)",
                  },
                  { schedule: "10-12 weeks: DHPP, Bordetella, Leptospirosis" },
                  { schedule: "12-16 weeks: DHPP, Leptospirosis, Rabies" },
                  {
                    schedule:
                      "Annual boosters: DHPP, Bordetella, Leptospirosis, Rabies (depending on local laws)",
                  },
                ].map((item, index) => (
                  <li key={index} className='flex items-center'>
                    <ShieldCheckIcon className='h-5 w-5 text-blue-500 mr-2' />
                    <span className='text-gray-600'>{item.schedule}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
            <motion.div className='mt-10' variants={staggerListAnimation(0.4)}>
              <h2 className='text-2xl font-bold text-gray-800'>Notes</h2>
              <p className='text-gray-600 mt-4'>
                <span className='font-semibold'>Consult a Veterinarian:</span>{" "}
                Always consult with a veterinarian to determine the best
                vaccination schedule for your dog based on its health,
                lifestyle, and local regulations.
              </p>
              <p className='text-gray-600 mt-4'>
                <span className='font-semibold'>Keep Records:</span> Maintain a
                record of all vaccinations for reference and proof, especially
                if traveling or boarding your dog.
              </p>
            </motion.div>
          </div>
        </motion.div>
        <Footer />
      </div>
    </AnimatePresence>
  );
}
