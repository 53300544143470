import { useRouteError } from "react-router-dom";
import { NavTop } from "../components"; // Adjust the path accordingly

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='flex min-h-screen w-full m-0 p-0'>
      <NavTop />
      <div className='flex flex-col justify-center items-center w-full pt-[100px]'>
        <div className='flex margin-auto flex-col text-center'>
          {" "}
          <h1 className='text-red-500 text-8xl font-semibold mb-2.5'>Oops!</h1>
          <p className='text-slate-500 mb-5'>
            Sorry, an unexpected error has occurred.
          </p>
          <p className='text-red-800'>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
    </div>
  );
};
