import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className='flex flex-col w-full min-h-[250px] px-2.5 md:px-5 bg-zinc-800	items-center justify-between'>
      <div className='flex flex-col md:flex-row w-full p-5 pt-20 md:p-20 gap-10'>
        <div className='flex flex-col w-full md:w-[25%] gap-2.5'>
          <h3 className='text-blue-500 text-lg font-semibold'>Top Category</h3>
          {/* <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            About Us
          </p> */}
          <a
            href='/#about-page'
            className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'
          >
            About Us
          </a>
          <a
            href='/#blog-page'
            className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'
          >
            Blog
          </a>
          <a
            href='/#breed-page'
            className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'
          >
            Our popular breeds
          </a>
          <a
            href='/#testimonials-page'
            className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'
          >
            Testimonials
          </a>
          <a
            href='/#about-page'
            className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'
          >
            Privacy & Policy
          </a>
        </div>
        <div className='flex flex-col w-full md:w-[25%] gap-2.5'>
          <h3 className='text-blue-500  text-lg font-semibold'>
            Training & Services
          </h3>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            Grooming & Veterinary Care
          </p>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            Puppy Training
          </p>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            Group Obedience Training
          </p>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            Private Lessons
          </p>
        </div>
        <div className='flex flex-col w-full md:w-[25%] gap-2.5'>
          <h3 className='text-blue-500  text-lg font-semibold'>
            Follow Social Media
          </h3>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            X
          </p>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            Instagram
          </p>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            help@manstopper.co.uk
          </p>
          <p className='text-blue-100 hover:text-blue-400 cursor-pointer outline-none'>
            watsapp
          </p>
        </div>
        <div className='flex flex-col w-full md:w-[25%] gap-2.5'>
          <h3 className='text-blue-500 text-lg font-semibold'>Social links</h3>

          <div className='flex flex-row'>
            <input
              type='text'
              className='text-sm text-slate-500 p-2.5 border-none outline-none bg-slate-300 rounded-sm mt-5'
              placeholder='Enter your email address here'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center w-full pb-20 px-5 pt-10 md:px-20 gap-10'>
        <div className='flex w-[100px]'>
          <Link
            href={"/"}
            className='flex flex-row w-full h-[40px] items-center outline-none cursor-pointer'
          >
            <img
              // width={100}
              // height={30}
              src={"/logo.jpg"}
              className='h-[80px] w-[80px] rounded-full'
              alt='Manstoper Kennels'
            />
            {/* <p className='text-md text-white font-semibold'>
              {" "}
              &nbsp; Manstoper Kennels
            </p> */}
          </Link>
        </div>
        <div className='flex flex-1'>
          <p className='font-sm text-blue-100'>
            © {new Date().getFullYear()} Manstoper Kennels.
          </p>
        </div>
      </div>
    </div>
  );
};
