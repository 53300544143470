import React from "react";
import { BentoGrid, BentoGridItem } from "./ui/bento-grid";

export function ServicesBentoGrid() {
  return (
    <BentoGrid className='max-w-6xl'>
      {services.map((item, i) => (
        <BentoGridItem
          key={i}
          index={i}
          title={item.title}
          description={item.description}
          header={item.header}
          className={i === 3 || i === 6 ? "md:col-span-2" : ""}
        />
      ))}
    </BentoGrid>
  );
}
const Skeleton = ({ img }) => (
  <div
    className='bento-card-img-container flex flex-1 w-full h-full min-h-[16rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100'
    style={{
      backgroundImage: `url(${img || "/img/sample_dogs/police_ready.jpg"})`,
    }}
  ></div>
);
const services = [
  {
    title: "Protection Training",
    description:
      "Our expert trainers specialize in comprehensive protection training, equipping your Malinois with the skills and discipline to be a formidable guardian.",
    header: <Skeleton img={"/img/services/protection.jpg"} />,
  },
  {
    title: "Police-Trained Dogs",
    description:
      "We offer fully trained police dogs, equipped with the skills needed for law enforcement and security tasks.",
    img: "/img/paw_img.png",
    header: <Skeleton img={"/img/sample_dogs/police_ready.jpg"} />,
  },
  {
    title: "Obedience Training",
    description:
      "We offer comprehensive obedience training programs to nurture a strong bond and ensure your Malinois is well-behaved, responsive, and under control.",
    header: <Skeleton img={"/img/services/obedience.jpg"} />,
  },
  {
    title: "Breeding",
    description:
      "We have UK's Top Malanois breeds. Our meticulous breeding program ensures the highest quality Belgian Malinois puppies with exceptional temperaments, intelligence, and physical attributes.",
    header: <Skeleton img={"/img/services/breeding.jpg"} />,
  },
  {
    title: "Agility Training",
    description:
      "Unlock your Malinois' athleticism and intelligence with our agility training, fostering their natural abilities and promoting a strong working relationship.",
    header: <Skeleton img={"/img/services/agility.jpg"} />,
  },
  {
    title: "Puppy Socialization",
    description:
      "Our puppy socialization programs lay the foundation for well-adjusted, confident, and friendly Malinois, ensuring a smooth transition to their forever homes.",
    header: <Skeleton img={"/img/services/socialization.jpg"} />,
  },
  {
    title: "Boarding Services",
    description:
      "We offer boarding services to take care of your dog while you are away. Your Malinois will enjoy a safe, comfortable, and engaging environment.",
    header: <Skeleton img={"/img/sample_dogs/social_skills.jpg"} />,
  },
  {
    title: "Grooming Services",
    description:
      "Our grooming services ensure your Malinois looks their best with regular baths, coat trimming, nail clipping, and ear cleaning.",
    header: <Skeleton img={"/img/services/grooming.webp"} />,
  },
  {
    title: "Nutritional Guidance",
    description:
      "We provide expert nutritional guidance to keep your Malinois healthy and energetic, tailored to their specific needs and lifestyle.",
    header: <Skeleton img={"/img/services/nutritional_guidance.webp"} />,
  },
  {
    title: "Search and Rescue Training",
    description:
      "Our search and rescue training program equips your Malinois with the skills and expertise needed to excel in search and rescue operations. Our dogs are trained to locate and assist in the recovery of missing persons, providing invaluable support in emergency situations.",
    header: <Skeleton img={"/img/services/search.jpg"} />,
  },
];
