import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Button } from "../components/ui/button";

export default function ContactUs({ contactRef }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:k9@abmanstopper.dog?subject=Inquiry&body=${formData.message}`;
    window.location.href = mailtoLink;
    setFormData({ name: "", email: "", message: "" });
  };

  const ref = useRef(null);

  const controls = useAnimation();
  const inView = useInView(ref, {
    once: true,
    amount: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={contactRef}
      className='flex  min-h-screen flex-col relative items-center justify-center py-80 bg-zinc-900 '
      id='contact-page'
    >
      {/* Poster image */}
      <img
        src='/img/contact-banner.png'
        alt='Contact us'
        className='block md:hidden contact-banner absolute top-0 left-0 w-full h-full object-cover'
      />

      <video
        className='hidden md:block absolute top-0 left-0 object-cover w-full h-full transition-opacity duration-1000'
        autoPlay
        loop
        muted
        playsInline
        poster='/img/contact-banner.png'
        playbackRate={0.1}
      >
        <source src='/videos/landing_dog_2.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>

      <motion.div
        initial='hidden'
        animate={controls}
        variants={containerVariants}
        className='flex flex-col items-center w-full gap-5'
      >
        <motion.h2
          className='z-10 text-4xl md:text-6xl mb-5 font-bold text-white text-center'
          variants={itemVariants}
        >
          Contact Us
        </motion.h2>
        <motion.p
          className='z-10 text-lg p-5 mb-20 text-slate-300 text-center max-w-3xl'
          variants={itemVariants}
        >
          At AB Manstoper Kennels Breeders, we value your inquiries and are here
          to assist you. Whether you have questions about our breeding program,
          training services, or simply want to learn more, we're ready to
          provide you with the information you need.
        </motion.p>
      </motion.div>

      <motion.form
        ref={ref}
        initial='hidden'
        animate={controls}
        variants={containerVariants}
        onSubmit={handleSubmit}
        className='p-5 z-10 flex flex-col w-full max-w-md space-y-8'
      >
        <motion.div variants={itemVariants} className='flex w-full'>
          <Input
            type='text'
            name='name'
            placeholder='Name'
            value={formData.name}
            onChange={handleChange}
            required
          />
        </motion.div>
        <motion.div variants={itemVariants} className='flex w-full'>
          <Input
            type='email'
            name='email'
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </motion.div>
        <motion.div variants={itemVariants} className='flex w-full'>
          <Textarea
            name='message'
            placeholder='Message'
            rows={4}
            value={formData.message}
            onChange={handleChange}
            required
          />
        </motion.div>
        <motion.div variants={itemVariants} className='flex w-full'>
          <Button
            type='submit'
            className='bg-blue-500 hover:bg-blue-600 w-full'
          >
            Submit
          </Button>
        </motion.div>
      </motion.form>
    </motion.div>
  );
}
