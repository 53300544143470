"use client";
import { motion } from "framer-motion";
import { Button } from "src/components//ui/button";

export const Modal = ({
  isVissible,
  showTitleClose,
  title,
  subTitle,
  handleCloseClick,
  children,
}) => {
  return (
    <>
      {isVissible ? (
        <>
          <motion.div className='flex fixed top-0 left-0 flex-col items-center justify-center h-screen w-full z-50 backdrop-blur-md '>
            <div className='flex flex-col items-center justif p-5 text-gray-900 rounded-xl w-full min-w-[350px] max-w-none md:max-w-lg min-h-full md:min-h-[300px] bg-white shadow-md'>
              {title && (
                <div className='flex flex-col w-full justify-items-start gap-3'>
                  <div className='flex flex-row w-full justify-items-start gap-5'>
                    <div className='flex flex-auto items-center'>
                      <h1 className='text-xl md:text-lg text-gray-900 font-semibold'>
                        {title}
                      </h1>
                    </div>

                    {showTitleClose ? (
                      <>
                        <div className='w-[40px]'>
                          <Button
                            handleClick={() => handleCloseClick()}
                            type='button'
                            className='w-[40px] h-[40px] text-center px-0 font-bold rounded-3xl'
                          >
                            x
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <p className='text-sm text-slate-500 font-semibold'>
                    {subTitle}
                  </p>
                </div>
              )}

              {children}
            </div>
          </motion.div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

