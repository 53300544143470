import {
  IoHomeOutline,
  IoPawOutline,
  IoCallOutline,
  IoPeopleOutline,
  IoAppsOutline,
} from "react-icons/io5";

export const links = [
  // {
  //   title: "Home",
  //   uri: "/",
  //   icon: <IoHomeOutline />,
  // },
  {
    title: "About",
    uri: "/#about-page",
    icon: <IoPawOutline />,
  },
  {
    title: "Blog",
    uri: "/blog",
    icon: <IoPeopleOutline />,
  },
  {
    title: "Services",
    uri: "/#service-page",
    icon: <IoAppsOutline />,
  },
  {
    title: "Testimonials",
    uri: "/#testimonials-page",
    icon: <IoPeopleOutline />,
  },

  {
    title: "Contacts",
    uri: "/#contact-page",
    icon: <IoCallOutline />,
  },
];
