import React, { useRef, useEffect } from "react";
import { motion, useAnimate, useInView } from "framer-motion";
import { NavTop, Footer } from "../components";
import About from "./About";
import Services from "./Services";
import Testimonials from "./Testimonials";
import KnowMore from "./KnowMore";
import ContactUs from "./ContactUs";
import { Button } from "../components/ui/button";
import { BreedGrids } from "../components/BreedGrids";
import FindCompanion from "../components/FindCompanion";

export default function Home() {
  const contactRef = useRef(null);
  const [scope, animate] = useAnimate(null);
  const isInView = useInView(scope, { amount: 0.5 });

  const handleContactClick = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (isInView) {
      enterHeroAnimation();
    } else {
      exitHeroAnimation();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const enterHeroAnimation = async () => {
    // await animate(scope.current, { opacity: 1, y: 0 }, { duration: 1 });
    await animate("h3", { opacity: 1, y: 0 }, { duration: 0.8, delay: 1 });
    await animate("p", { opacity: 1, y: 0 }, { duration: 0.5, delay: 0.2 });
    await animate(
      "#hero-btn",
      { opacity: 1, y: 0 },
      { duration: 1, delay: 0.2 }
    );
  };

  const exitHeroAnimation = async () => {
    // await animate(scope.current, { opacity: 0, y: -100 });
    await animate("h3", { opacity: 0, y: -20 });
    await animate("p", { opacity: 0, y: -20 });
    await animate("#hero-btn", { opacity: 0, y: -20 });
  };
  return (
    <>
      <div
        className='flex min-h-screen w-full m-0 p-0 relative'
        id='landing-page'
      >
        <NavTop />
        <motion.video
          className='absolute top-0 left-0 object-cover w-full h-full transition-opacity duration-1000'
          autoPlay
          loop
          muted
          playsInline
          poster='/img/banner-sm.jpg'
          playbackRate={0.1}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <source src='/videos/landing_dog_1.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </motion.video>
        <div className='flex w-full py-48 items-center relative z-10'>
          <div
            ref={scope}
            className='flex flex-col md:max-w-xl ml-[5%] md:ml-[10%] text-white'
          >
            <h3 className='mb-5 text-5xl md:text-8xl font-bold text-blue-400'>
              Belgian Malinois Breeders
            </h3>
            <p className='mb-5 text-slate-100 text-base md:text-2xl pr-2.5'>
              Specializing in security, alongside comprehensive dog training,
              conditioning, and obedience services. Discover your loyal and
              skilled companion today.
            </p>
            <div className='flex w-full'>
              <Button
                onClick={handleContactClick}
                type='button'
                id='hero-btn'
                className='bg-blue-500 w-full'
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>
      </div>
      <About />
      {/* <Breed /> */}
      <BreedGrids />
      <FindCompanion />
      <Services />
      {/* <ServicesBentoGrid /> */}
      <Testimonials />
      <KnowMore />
      <ContactUs contactRef={contactRef} /> {/* Pass contactRef as a prop */}
      <Footer />
    </>
  );
}
