import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { ServicesBentoGrid } from "../components/ServicesBentoGrid";
export default function Services() {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, {
    once: true,
    amount: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={controls}
      variants={containerVariants}
      className='flex min-h-screen w-full m-0 p-0 bg-zinc-900'
      id='service-page'
    >
      <div className='flex flex-col w-full py-80 px-5 items-center justify-center text-left '>
        <div className='flex flex-col items-center w-full md:w-1/2 max-w-[600px] text-white'>
          <motion.h2
            className='text-4xl p-5 md:text-6xl font-bold text-white text-center'
            variants={itemVariants}
          >
            Our Services
          </motion.h2>
          <motion.p
            className='text-lg p-5 mb-10 text-slate-300 text-center max-w-3xl'
            variants={itemVariants}
          >
            At AB Manstoper Kennels Breeders, we take pride in our commitment to
            breeding and training the finest Belgian Malinois dogs for security
            purposes. With a focus on excellence and reliability, we offer a
            range of exceptional services to cater to the needs of both dogs and
            their owners.
          </motion.p>
        </div>
        <div className='flex pt-20 w-full items-center'>
          <ServicesBentoGrid />
        </div>
      </div>
    </motion.div>
  );
}
