import React, { useEffect, useRef } from "react";
import { cn } from "src/lib/utils";
import { motion, useAnimation, useInView } from "framer-motion";

export const BentoGrid = ({ className, children }) => {
  return (
    <div
      className={cn(
        "grid md:auto-rows-[28rem] grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto ",
        className
      )}
    >
      {children}
    </div>
  );
};

export const BentoGridItem = ({
  index,
  className,
  title,
  description,
  header,
}) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, {
    once: true,
    amount: 0,
  });
  const randomX = (Math.random() - 0.5) * 20;
  const randomY = (Math.random() - 0.5) * 20;

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        x: 0,
        y: 0,
        transition: {
          delay: index * 0.3, // Slowing down the stagger speed
          type: "spring",
          stiffness: 50,
          damping: 20,
        },
      });
    }
  }, [controls, inView, index]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: randomX, y: randomY }}
      animate={controls}
      exit={{ opacity: 0, x: randomX, y: randomY }}
      className={cn(
        "h-max row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white/10 border border-transparent justify-between flex flex-col space-y-4",
        className
      )}
    >
      {header}
      <div className='group-hover/bento:translate-x-2 transition duration-200'>
        <div className='font-sans font-bold text-white dark:text-neutral-200 mb-2 mt-2'>
          {title}
        </div>
        <div className='font-sans font-normal text-white text-base dark:text-neutral-300'>
          {description}
        </div>
      </div>
    </motion.div>
  );
};
