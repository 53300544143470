import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";

export default function KnowMore() {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, {
    once: true,
    amount: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: .5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

   const articles = [
     {
       id: 1,
       title: "Nutritional Needs of Belgian Malinois",
       content: [
         "Malinois are high-energy dogs that require a balanced and nutrient-rich diet.",
         "Their meals should be rich in protein from sources like lean meat, fish, or eggs.",
         "Complex carbohydrates from whole grains and vegetables provide energy.",
         "Avoid excessive treats and table scraps to maintain a healthy weight.",
       ],
     },
     {
       id: 2,
       title: "Maintaining Medical Records",
       content: [
         "Keep accurate records of vaccinations, deworming, and other preventative care.",
         "Document any health issues, medications, or treatments received.",
         "Regularly update your Malinois' weight and body condition score.",
         "Share medical records with your veterinarian during check-ups.",
       ],
     },
     {
       id: 3,
       title: "Common Diseases in Belgian Malinois",
       content: [
         "Hip and elbow dysplasia are common skeletal disorders to watch for.",
         "Malinois are prone to certain eye conditions like progressive retinal atrophy.",
         "Regular exercise and a balanced diet can help prevent obesity-related issues.",
         "Early detection and treatment are crucial for any health concerns.",
       ],
     },
   ];

   return (
     <div
       className='flex flex-col items-center relative gap-10 pl-5 md:pl-10 pt-48 pb-80 w-full m-0  bg-zinc-900'
       id='know-more-page'
     >
       <motion.div
         ref={ref}
         initial='hidden'
         animate={controls}
         variants={containerVariants}
         className='flex flex-col gap-2.5 text-center'
       >
         <motion.h2
           className='text-4xl p-5 md:text-6xl font-bold text-white text-center'
           variants={itemVariants}
         >
           Know Your Buddy
         </motion.h2>
         <motion.p
           className='text-lg p-5 mb-10 text-slate-300 text-center max-w-3xl'
           variants={itemVariants}
         >
           At AB Manstoper Kennels Breeders, we aim to provide you with help
           tips and tricks to help our community members better in taking care
           of their dogs. These are some of the frequently asked questions.
         </motion.p>
       </motion.div>

       <motion.div
         ref={ref}
         initial='hidden'
         animate={controls}
         variants={containerVariants}
         className='flex flex-col max-w-5xl mx-auto gap-8 p-5'
       >
         <Accordion type='single' collapsible className='w-full text-white'>
           {articles.map((article, index) => (
             <motion.div key={article.id} variants={itemVariants}>
               <AccordionItem
                 key={article.id}
                 className='mb-10 border-zinc-700'
                 value={`item-${index + 1}`}
               >
                 <AccordionTrigger className='text-xl md:text-3xl text-left font-semibold text-white'>
                   {article.title}
                 </AccordionTrigger>
                 <AccordionContent className='flex flex-col gap-5 py-20'>
                   {article.content.map((point, pointIndex) => (
                     <p
                       className='flex text-lg items-center gap-2 text-white '
                       key={pointIndex}
                     >
                       <ShieldCheckIcon className='hidden md:block h-5 w-5 text-blue-300' />
                       {point}
                     </p>
                   ))}
                 </AccordionContent>
               </AccordionItem>
             </motion.div>
           ))}
         </Accordion>

         {/* {articles.map((article, index) => (
          <motion.div
            key={article.id}
            className='flex flex-col w-max md:w-md px-10 py-20 animate-slide-x rounded-lg bg-white/10 backdrop-blur-xl '
            {...staggerListAnimation(index * 2)}
          >
            <h3 className='text-3xl font-semibold text-white mb-10'>
              {article.title}
            </h3>

            <div className='flex flex-col gap-5 flex-wrap w-full'>
              {article.content.map((point, index) => (
                <p
                  className='flex items-center gap-2 text-base text-white w-max'
                  key={index}
                >
                  <ShieldCheckIcon className='h-5 w-5 text-blue-300' />
                  {point}
                </p>
              ))}
            </div>
          </motion.div>
        ))} */}
       </motion.div>

       {/* <ScrollArea
        className='w-full md:w-max whitespace-nowrap rounded-md '
        id='know-scroll-section'
      >
        <ScrollBar orientation='horizontal' />
      </ScrollArea> */}
     </div>
   );
}
