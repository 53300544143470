import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

import LayoutGrid from "./ui/layout-grid";

export function BreedGrids() {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, {
    once: true,
    amount: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className='min-h-screen h-max w-full bg-zinc-900'>
      <motion.div
        ref={ref}
        initial='hidden'
        animate={controls}
        variants={containerVariants}
        className='flex flex-col items-center mb-10 pt-40'
      >
        <motion.h2
          className='text-4xl p-5 md:text-6xl font-bold text-white text-center'
          variants={itemVariants}
        >
          Our Breeds
        </motion.h2>
        <motion.p
          variants={itemVariants}
          className='text-lg p-5 mb-10 text-slate-300 text-center max-w-3xl'
        >
          Experience the pinnacle of canine loyalty and protection with our
          exceptional Belgian Malinois breeds. Renowned for their unwavering
          commitment, intelligence, and athleticism, our dogs are the epitome of
          guardians and family companions bread from UK's finest bloodlines.
          Best for security and protection.
        </motion.p>
      </motion.div>
      <div className='flex flex-col mt-40 h-[1500px]'>
        <LayoutGrid cards={cards} />
      </div>
    </div>
  );
}

const SkeletonGenerator = ({ title, description }) => {
  return (
    <div>
      <p className='font-bold text-4xl text-white'>{title}</p>
      <p className='font-normal text-base text-white'></p>
      <p className='font-normal text-base my-4 max-w-lg text-neutral-200'>
        {description}
      </p>
    </div>
  );
};

const cards = [
  {
    id: 9,
    content: (
      <SkeletonGenerator
        title='Top European Bloodlines'
        description='Our dogs come from top European bloodlines, ensuring the highest quality traits and exceptional lineage.'
      />
    ),
    className: "col-span-1",
    thumbnail: "/img/sample_dogs/european_bloodlines.jpg",
  },
  {
    id: 10,
    content: (
      <SkeletonGenerator
        title='Police Ready Trained'
        description='Our dogs are trained to meet police standards, making them ready for professional work and advanced security tasks.'
      />
    ),
    className: "col-span-2",
    thumbnail: "/img/sample_dogs/police_ready.jpg",
  },
  {
    id: 8,
    content: (
      <SkeletonGenerator
        title='Social Skills'
        description='Properly socialized Malinois are friendly and well-mannered, getting along well with people and other animals.'
      />
    ),
    className: "col-span-2",
    thumbnail: "/img/sample_dogs/social_skills.jpg",
  },
  {
    id: 2,
    content: (
      <SkeletonGenerator
        title='Intelligence'
        description='Highly intelligent and eager to learn, our Malinois excel in various disciplines, from protection to agility.'
      />
    ),
    className: "col-span-1",
    thumbnail: "/img/services/search.jpg",
  },
  {
    id: 1,
    content: (
      <SkeletonGenerator
        title='Loyalty'
        description='Our Malinois form an unbreakable bond with their owners, ensuring unwavering loyalty and protection.'
      />
    ),
    className: "md:col-span-1",
    thumbnail: "/img/sample_dogs/loyalty.jpg",
  },

  {
    id: 6,
    content: (
      <SkeletonGenerator
        title='Protectiveness'
        description='Our Malinois are naturally protective, making them excellent guard dogs that will keep your family safe.'
      />
    ),
    className: "col-span-2",
    thumbnail: "/img/sample_dogs/protectiveness.jpg",
  },

  {
    id: 3,
    content: (
      <SkeletonGenerator
        title='Athleticism'
        description='With their remarkable endurance and agility, our Malinois are natural athletes ready for any challenge.'
      />
    ),
    className: "col-span-1",
    thumbnail: "/img/sample_dogs/athleticism.jpeg",
  },
  {
    id: 4,
    content: (
      <SkeletonGenerator
        title='Trainability'
        description='Known for their quick learning and responsiveness, our Malinois are highly trainable and thrive on positive reinforcement.'
      />
    ),
    className: "col-span-1",
    thumbnail: "/img/sample_dogs/trainability.jpg",
  },

  {
    id: 7,
    content: (
      <SkeletonGenerator
        title='Energy'
        description='With high energy levels, our Malinois are perfect for active owners who enjoy outdoor activities and exercise.'
      />
    ),
    className: "col-span-1",
    thumbnail: "/img/sample_dogs/energy.jpg",
  },
  {
    id: 5,
    content: (
      <SkeletonGenerator
        title='Versatility'
        description='From search and rescue to therapy work, our Malinois are versatile dogs capable of excelling in multiple roles.'
      />
    ),
    className: "col-span-2",
    thumbnail: "/img/sample_dogs/versatility.jpg",
  },
];
