import React, { useEffect, useState, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../components/ui/carousel";
import { Card, CardContent } from "../components/ui/card";
import Autoplay from "embla-carousel-autoplay";
import { StarIcon } from "@heroicons/react/24/solid";
import { headerAnimation } from "../lib/motion";

export default function Testimonials() {
  const [api, setApi] = useState();
  const plugin = useRef(Autoplay({ delay: 3000, stopOnInteraction: true }));

  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, {
    once: true,
    amount: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const testimonials = [
    {
      id: 1,
      image: "/img/avator/john.jpg",
      name: "John Doe",
      rating: 5,
      review:
        "The Belgian Malinois we got from AB Manstoper Kennels Breeders is simply amazing! Highly intelligent, loyal, and well-trained. We couldn't be happier with our furry companion.",
      date: "May 10, 2023",
    },
    {
      id: 2,
      image: "/img/avator/jane.jpg",
      name: "Jane Smith",
      rating: 4,
      review:
        "The team at AB Manstoper Kennels Breeders truly cares about their dogs. The puppy socialization program was excellent, and our Malinois is now a well-adjusted family member.",
      date: "April 15, 2023",
    },
    {
      id: 3,
      image: "/img/avator/michael.jpg",
      name: "Michael Johnson",
      rating: 5,
      review:
        "I highly recommend the protection training services at AB Manstoper Kennels Breeders. My Malinois is now a formidable guardian, and I feel much safer knowing he's by my side.",
      date: "March 20, 2023",
    },
    {
      id: 4,
      image: "/img/avator/emily.jpg",
      name: "Emily Wilson",
      rating: 4,
      review:
        "The agility training program was fantastic! My Malinois is now a champion in competitions, and we have a stronger bond than ever before.",
      date: "February 5, 2023",
    },
    {
      id: 5,
      image: "/img/avator/david.jpg",
      name: "David Thompson",
      rating: 5,
      review:
        "AB Manstoper Kennels Breeders exceeded our expectations. The breeding program is exceptional, and our Malinois puppy is healthy, intelligent, and full of energy.",
      date: "January 28, 2023",
    },
  ];

  useEffect(() => {
    if (!api) {
      return;
    }
  }, [api]);

  return (
    <div
      className='flex flex-col items-center justify-center py-20 bg-zinc-900'
      id='testimonials-page'
    >
      <motion.div
        ref={ref}
        initial='hidden'
        animate={controls}
        variants={containerVariants}
        className='flex flex-col gap-2.5 text-center'
      >
        <motion.h2
          className='text-4xl p-5 md:text-6xl font-bold text-white text-center'
          variants={itemVariants}
        >
          Our Satisfied Customers
        </motion.h2>
        <motion.p
          variants={itemVariants}
          className='text-lg p-5 mb-10 text-slate-300 text-center max-w-3xl'
        >
          At AB Manstoper Kennels Breeders, we take immense pride in providing
          exceptional services and breeding the finest Belgian Malinois dogs.
          Our customers' satisfaction is our top priority, and we strive to
          exceed their expectations with every interaction.
        </motion.p>
      </motion.div>

      <Carousel
        className='w-full  mt-20 mb-48'
        options={{
          defaultIndex: 0,
          loop: true,
          draggable: true,
        }}
        plugins={[plugin.current]}
        onInit={setApi}
      >
        <CarouselContent className='flex gap-5 justify-start md:justify-center'>
          {testimonials.map((testimonial, index) => (
            <div key={testimonial.id}>
              <CarouselItem className='md:basis-1/2 lg:basis-1/3 h-[100%] md:mr-10'>
                <Card className='w-full min-w-[300px] md:w-96 h-[300px] py-8 px-2.5  shadow-md bg-white/10 border-zinc-700 backdrop-blur-sm overflow-hidden hover:shadow-lg '>
                  <CardContent>
                    <div className='flex items-center mb-4'>
                      <div className='flex w-16 h-16 rounded-full mr-4 border-4 border-zinc-500'>
                        <img
                          src={testimonial.image}
                          alt={testimonial.name}
                          className='w-full rounded-full'
                        />
                      </div>

                      <div>
                        <h3 className='text-xl font-semibold text-white'>
                          {testimonial.name}
                        </h3>
                        <div className='flex items-center text-yellow-400'>
                          {Array.from(
                            { length: testimonial.rating },
                            (_, i) => (
                              <StarIcon key={i} className='h-5 w-5' />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <p className='text-slate-300 mb-4'>{testimonial.review}</p>
                    <p className='text-slate-400 text-sm'>{testimonial.date}</p>
                  </CardContent>
                </Card>
              </CarouselItem>
            </div>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
}
